import { ArrowLongLeftIcon } from '@heroicons/react/24/solid'

const BackButton = ({
    action = () => {}
}) => {
    return <div
        onClick={action}
        className="py-0 text-center flex gap-1 font-normal text-sm text-[350] uppercase text-[#FF7C9B] cursor-pointer"
    >
        <ArrowLongLeftIcon className='h-4'/>
        <p>ATRÁS</p>
    </div>
}

export default BackButton