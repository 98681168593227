import { useNavigate } from "react-router-dom"

import HomeHeader from './header'
import HeaderLogo from '../../common/header'
import Footer from '../../common/footer'
import BodyComponent from './body'
import Button from '../../common/button'

import './index.css'

const HomePage = () => {
    
    let navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/dinner');
    }
    const handleDonation = () => {
        window.location.href = 'https://checkout.wompi.co/l/VPOS_lhARkH'
    }

    return <div className='home-page grid grid-flow-row'>
        <HeaderLogo />
        <HomeHeader goToDinner={handleNavigation} goToDonate={handleDonation} />
        <BodyComponent goToDinner={handleNavigation} />
        <Footer />
        <div className='fixed flex gap-4 top-1/3 right-0 translate-x-[10.3rem] -rotate-90 '>
            <Button
                text='Quiero Donar'
                alt='Quiero Donar'
                type='transparent'
                action={handleDonation}
            />
            <Button
                text='ASISTIR A LA CENA'
                alt='Asistir a la cena'
                action={handleNavigation}
            />
        </div>
    </div>
}

export default HomePage;