import LogoWhitefrom from '../../resources/Logo-Blanco.png';

const Footer = () => {
    return <div 
        className="bg-[#e34462] text-white text-center py-4"
    >   
        <img
            className='mx-auto h-[180px]'
            src={LogoWhitefrom}
            alt='Poderosas Logo White'
        />
        <p>Todos los derechos reservados</p>
        <a href='#' alt='Policies'>Política de Privacidad</a>
        {process.env.REACT_APP_ENV && <p>Test</p>}
    </div>
}

export default Footer