
const BodyItem = ({
    img = '',
    alt = '',
    text = ''
}) => {
    return <div className="text-center text-white text-xl font-bold sm:text-2xl sm:font-[350]">
        <img
            className="mx-auto h-[170px] w-auto"
            src={img}
            alt={alt} 
        />
        <p className="pt-4 w-[70%] mx-auto">{text}</p>
    </div>
}

export default BodyItem