const Steps = ({
    step = '1'
}) => {
    const styleSelected = 'text-[#FF7C9B] border-4 border-[#FF7C9B] rounded-full px-[6px]'
    const styleNoSelected = 'border-4 border-transparent rounded-full px-[6px]'
    return <div
        className="py-4 text-center flex mx-auto gap-4 text-white font-bold text-sm"
    >
        <p className={step === 1 ? styleSelected : styleNoSelected}>1</p>
        <p className={step === 2 ? styleSelected : styleNoSelected}>2</p>
        <p className={step === 3 ? styleSelected : styleNoSelected}>3</p>
    </div>
}

export default Steps