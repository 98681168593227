import { useForm } from "react-hook-form";

import Button from '../../../common/button'

const StepOne = ({
    handleStep = () => {},
    stepOneData = {}
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: stepOneData
    });

    const onSubmit = (data) => {
        handleStep(2, data)
        // console.log(errors)
    }

    const inputTextStyles = "block w-full text-gray rounded-md pl-2 pr-4 focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
    const spanStyles = "block text-white text-lg"
    const labelStyles = "block pb-2"
    const errorStyles = "block pt-2 text-[#e34462] text-sm"

    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <div className="w-[95%] mx-auto grid md:grid-cols-2 md:grid-flow-col">
            <div className="text-white text-center self-center">
                <h1 className='text-[#FF7C9B] hashtag py-4 text-4xl sm:text-[50px]'>
                    #LaCenaMasPoderosaDelAño 2022 
                </h1>
                
                <p className='p w-11/12 mx-auto sm:text-lg'>
                    Una <strong>experiencia gastronómica, social y educativa</strong> para sentarnos en la mesa a hablar de lo que no se habla.
                    Será en el <strong>restaurante Prudencia, en la Candelaria, Bogotá</strong>. Es cocina de autor, deliciosa y mega reconocida por su exquisita propuesta.
                    <br />
                    <br />
                    El <strong>martes 1 y miércoles 2 de Noviembre 6:00pm.</strong>
                    <br />
                    <br />
                    {/* Vendrán desde Barú, Urabá, Cali y Cartagena viajen <strong>4 Poderosas</strong> por primera vez a Bogotá a contar cómo una
                    <strong>#EducaciónPoderosa impactó su vida</strong> y porqué es urgente asumir esta <strong>#AsignaturaPendiente</strong> para sus comunidades ypara el país. */}
                    Desde sus comunidades tres Poderosas y dos Poderosos viajarán por primera vez a Bogotá a contarnos su testimonio, cómo una educación poderosa transformó su vida y porqué es urgente asumir esta <strong>#AsignaturaPendiente</strong>.
                    <br />
                    <br />
                    Va a ser una experiencia espectacular.
                </p>
            </div>

            <div className="mt-8 sm:mt-0">
                <h1 className="text-[#FF7C9B] text-center font-bold text-2xl">
                    Eres importante para nosotrxs
                </h1>
                <p className="text-white text-center sm:text-lg pb-2">
                    Y queremos mantenernos en contacto, por eso necesitamos que llenes este breve formulario.
                </p>
                <form
                    className="sm:grid sm:gap-2 w-11/12 sm:w-full mx-auto"
                    // className="grid gap-2 grid-cols-1 sm:grid-cols-2"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {/* name - Nombre y apellido */}
                    <label className={labelStyles}>
                        <span className={spanStyles}>
                            Nombre y apellido
                        </span>
                        <input
                            className={inputTextStyles}
                            {...register(
                                "name", { required: true, maxLength: 50 }
                            )}
                            type="text"
                            placeholder="Escribe tu nombre aquí"
                        />
                        {errors.name && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
                    </label>

                    {/* email - Correo electrónico */}
                    <label className={labelStyles}>
                        <span className={spanStyles}>
                            Correo electrónico 
                        </span>
                        <input
                            className={inputTextStyles}
                            {...register(
                                "email", { required: true, maxLength: 50 }
                            )}
                            type="email"
                            placeholder="Escribe tu correo electrónico aquí"
                        />
                        {errors.email && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
                    </label>

                    {/* twitter_acc - Cuenta de Twitter */}
                    <label className={labelStyles}>
                        <span className={spanStyles}>
                            Añade tu @ de Twitter
                        </span>
                        <input
                            className={inputTextStyles}
                            {...register(
                                "twitter_acc", { required: false, maxLength: 50 }
                            )}
                            type="text"
                            placeholder="Escribe tu @ de Twitter"
                        />
                    </label>

                    {/* instagram_acc - Cuenta de instagram */}
                    <label className={labelStyles}>
                        <span className={spanStyles}>
                            Añade tu @ de Instagram
                        </span>
                        <input
                            className={inputTextStyles}
                            {...register(
                                "instagram_acc", { required: false, maxLength: 50 }
                            )}
                            type="text"
                            placeholder="Escribe tu @ de Instagram"
                        />
                    </label>

                    {/* person - Tipo de persona */}
                    <div className="block text-lg pb-2">
                        <span className={spanStyles}>
                            Tipo de persona
                        </span>
                        <div>
                            <input
                                type="radio"
                                className="h-4 w-4 text-[#FF7C9B] focus:ring-[#FF7C9B]"
                                {...register(
                                    "type_person", { required: true }
                                )}
                                value='natural'
                            />
                            <label htmlFor="type_person" className="ml-3 text-base text-white">
                                Natural
                            </label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                className="h-4 w-4 text-[#FF7C9B] focus:ring-[#FF7C9B]"
                                {...register(
                                    "type_person", { required: true }
                                )}
                                value='juridica'
                            />
                            <label htmlFor="type_person" className="ml-3 text-base text-white">
                                Jurídica
                            </label>
                        </div>
                        {errors.type_person && 
                            <span className={errorStyles}>
                                Selecciona una opción para continuar
                            </span>
                        }
                    </div>

                    {/* phone - Numero de telefono */}
                    <label className={labelStyles}>
                        <span className={spanStyles}>
                            Número de teléfono
                        </span>
                        <input
                            className={inputTextStyles}
                            {...register(
                                "phone", { required: true, maxLength: 50 }
                            )}
                            type="text"
                            placeholder="Escribe tu número de teléfono aquí"
                        />
                        {errors.phone && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
                    </label>

                    {/* type_document - Tipo de documento */}
                    <label className={labelStyles}>
                        <span className={spanStyles}>
                            Tipo de documento
                        </span>
                        <select
                            className="block w-full rounded-md border bg-white py-2 px-3 shadow-sm focus:border-[#FF7C9B] focus:outline-none focus:ring-[#FF7C9B] sm:text-sm"
                            {...register(
                                "type_document", { required: true}
                            )}
                            placeholder='s'
                        >
                            <option value="CC">Cédula de ciudadanía</option>
                            <option value="CE">Cédula de extranjería</option>
                            <option value="PA">Pasaporte</option>
                        </select>
                        {errors.type_document && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
                    </label>

                    {/* document - Número de documento */}
                    <label className={labelStyles}>
                        <span className={spanStyles}>
                            Tipo de documento
                        </span>
                        <input
                            className={inputTextStyles}
                            {...register(
                                "document", { required: true, maxLength: 50 }
                            )}
                            type="text"
                            placeholder="Escribe tu número de documento aqui"
                        />
                        {errors.document && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
                    </label>

                    {/* policies - Número de documento */}
                    <label className={labelStyles + ' col-start-1 col-end-3'}>
                        <span className={spanStyles}>
                            Permisos y políticas
                        </span>
                        <input
                            className="h-4 w-4 rounded text-[#FF7C9B] focus:ring-[#FF7C9B]"
                            {...register(
                                "policies", { required: true }
                            )}
                            type="checkbox"
                        />
                        <label htmlFor="policies" className="pl-4 text-white">
                            Acepto la <a
                                href="https://firebasestorage.googleapis.com/v0/b/poderosas-colombia.appspot.com/o/AUTORIZACIO%CC%81N%20PARA%20EL%20TRATAMIENTO%20DE%20DATOS.docx.pdf?alt=media&token=d47ff3cc-e210-443f-9f3c-2b508141bb56"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="text-[#FF7C9B] cursor-pointer">
                                    política de tratamiento de datos
                                </span>
                            </a>
                        </label>
                        {errors.policies && 
                            <span className={errorStyles}>
                                Debes aceptar las políticas de tratamiento de datos para continuar
                            </span>
                        }
                    </label>

                    <div className="mx-auto py-4 col-start-1 col-end-3">
                        <Button
                            text="CONTINUAR"
                            alt="Continuar"
                            btnType="submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default StepOne