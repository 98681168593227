
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import Button from '../../../common/button'

import './index.css'


const Header = ({
    goToDinner = () => {},
    goToDonate = () => {}
}) => {
    const handleDown = () => {
        document.getElementById("bodyComponent").scrollIntoView({
            behavior: 'smooth',
        })
    }

    return <div className='header text-white text-center'>
        <h2 className='h2 text-[16px] font-bold mt-10 sm:text-[24px] sm:font-[350] sm:mt-20'>
            Felicitaciones por asumir esta
            <span className='block span text-[26px] font-bold sm:text-[38px]'>#AsignaturaPendiente</span>
            tienes dos maneras de hacerlo:
        </h2>

        <div className='grid justify-items-center text-center gap-6 py-8 text-m'>
            {
                window.innerWidth < 640 ? 
                <Button
                    text='Asistir a la cena'
                    type='solid'
                    action={goToDinner}
                />
                :
                <Button
                    text='Asistir a la cena mas poderosa del año'
                    type='solid'
                    action={goToDinner}
                />
            }
            <Button
                text='Donar'
                type='transparent'
                action={goToDonate}
            />
        </div>

        <p className='w-10/12 mx-auto font-bold sm:font-normal'>Conoce más sobre esta asignatura pendiente</p>
        <div className='h-[25px] text-[#FF7C9B] text-center my-4'>
            <ChevronDownIcon
                onClick={handleDown}
                className='h-full mx-auto cursor-pointer'
            />
        </div>
    </div>
}

export default Header