import LogoImage from'../../resources/Logo-Rojo.png';

const Header = () => {
    return <a
    className='cursor-pointer py-4'
    href={process.env.REACT_APP_PODEROSAS_URL}
>
    <img
        className='mx-auto h-[90px]'
        src={LogoImage}
        alt='Poderosas Logo Red'
    />
</a>
}

export default Header