import BodyItem from "../../../common/bodyItem"
import Button from '../../../common/button'

import PaisViolento from '../../../resources/2-PAIS VIOLENTO 1.png'
import Embarazo from '../../../resources/Group 17.png'
import VidaSexual from '../../../resources/6-jovenes inician vida sexual 1.png'
import DelitosSexuales from '../../../resources/7-delitos 1.png'
import Binoculos from '../../../resources/Group 18.png'

const BodyComponent = ({
    goToDinner = () => {}
}) => {
    return <div id='bodyComponent' className="py-16 grid grid-flow-row items-center gap-32 sm:gap-40">
        <div className="text-center">
            <h2 className="text-white text-xl font-bold sm:text-2xl sm:font-[350]">
                En Colombia hay
                <span className="block text-[#FF7C9B] font-bold text-4xl">
                    19,6 millones
                </span> 
                de personas en la pobreza.
            </h2>
        </div>
        <BodyItem
            img={PaisViolento}
            text="Somos un país desigual, violento"
            alt="Imagen Bomba"
        />
        <BodyItem
            img={Embarazo}
            text="1 de cada 5 mujeres en embarazo es menor de 19 años"
            alt="Imagen embarazo"
        />
        <div className="text-center w-8/12 mx-auto sm:w-full">
            <h2 className="text-white text-lg font-bold sm:text-2xl sm:font-[350]">
                Después de décadas de bajar la tasa de embarazo infantil y <br/>
                adolescente, desde la pandamia, esta aumentó 
                <span className="block text-[#FF7C9B] font-bold text-4xl">
                    19,5%
                </span> 
                y sigue subiendo.
            </h2>
        </div>
        <BodyItem
            img={VidaSexual}
            text="Las y los jóvenes inician su vida sexual en promedio entre los 14 
            y 15 años y solo el 10% sabe dónde encontrar información verídica sobre 
            sexo y reproducción."
            alt="Imagen vida sexual"
        />
        <BodyItem
            img={DelitosSexuales}
            text='Los delitos sexuales aumentaron a nivel nacional, el 87% de sus víctimas son niñas y adolescentes.
            La violencia basada en género también aumentó, tanto en zonas urbanas como rurales.'
            alt='imagen delitos sexuales'
        />
        <div className="text-center w-8/12 mx-auto sm:w-full">
            <h2 className="text-white text-xl font-bold sm:text-2xl sm:font-[350]">
                <span className="block text-[#FF7C9B] font-bold text-4xl">
                    69,7%
                </span> 
                de los colegios en Colombia no han capacitado a las <br/>
                y los docentes en educación sexual.
            </h2>
        </div>
        <BodyItem
            img={Binoculos}
            text='Fortalecer el poder de decisión y la agencia a través de una educación en derechos es la herramienta más
            Poderosa para prevenir el embarazo no deseado, la violencia en todas sus formas y potenciar el liderazgo,
            por la equidad de género y escapar trampas de pobreza.'
            alt='imagen binoculos'
        />
        <div className="text-center text-white">
            <h2 className="uppercase text-[#e34462] text-[70px] sm:text-[100px] font-['Amatic_SC']">
                PODEROSAS
            </h2>
            <h4 className="text-lg font-bold sm:text-xl sm:font-[350]">Asumamos esta</h4>
            <h3 className="capitalize text-2xl sm:text-5xl font-bold pb-4">Asignatura Pendiente.</h3>
            <p className="w-9/12 sm:w-full mx-auto text-lg sm:text-xl font-[350] pb-8">
                Ven a vivir una experiencia gastronómica, social y educativa el 1 o <br/>
                2 de noviembre, para sentarnos en la mesa a escuchar a <strong>3 <br/>
                Poderosas y 2 Poderosos</strong> que viajarán por primera vez a Bogotá <br/>
                a contarte cómo <strong>#UnaEducaciónPoderosa</strong> impactó su vida y por <br/>
                qué es una asignatura urgente. 
            </p>
            <p className="w-9/12 sm:w-full mx-auto text-xl font-[350] pb-4">Ayuda a la educación sexual en Colombia</p>
            <Button
                text='ASISTIR A LA CENA'
                alt='Asistir a la cena'
                action={goToDinner}
            />
        </div>
    </div>
}

export default BodyComponent