import axios from 'axios'

export const useUpdateDonationWithTransaction = async (transaction) => {
    try {
        const res = await axios({
            method: 'POST',
            url: process.env.REACT_APP_FUNCTIONS_URL + '/saveTransactionInDonation',
            headers: {
                authorization: `Bearer ${process.env.REACT_APP_FUNCTIONS_TOKEN}`
            },
            data: {
                id: transaction.reference,
                status: transaction.status,
                transactionId: transaction.id,
                transaction: transaction,
                test: process.env.REACT_APP_ENV
            }
        })
        return res.data
    } catch (error) {
        // console.log('err usePaymentFunction:', error)
        throw new Error(error)
    }
}