import { useForm } from "react-hook-form";
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import Button from '../../../common/button'

const StepThree = ({
    handleStep = () => {},
    stepThreeData = {}
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: stepThreeData
    });

    const onSubmit = (data) => {
        handleStep(4, data)
        // console.log(errors)
    }

    const inputTextStyles = "block w-[20rem] sm:w-[25rem] text-gray rounded-md pl-2 pr-4 focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
    const spanStyles = "block text-white text-lg"
    const labelStyles = "block pb-2"
    const errorStyles = "block pt-2 text-[#e34462] text-lg"

    return <form
        className='sm:w-full mx-auto gap-2'
        onSubmit={handleSubmit(onSubmit)}
    >
        {/* address - Dirección */}
        <label className={labelStyles}>
            <span className={spanStyles}>
                Dirección
            </span>
            <input
                className={inputTextStyles}
                {...register(
                    "address", { required: true, maxLength: 50 }
                )}
                type="text"
                placeholder="Escribe tu dirección aquí"
            />
            <div className="pt-2 text-white">
                <ExclamationCircleIcon className="inline h-[20px] pr-2"/>
                <span className="inline-block italic text-white text-sm align-bottom">
                    Para enviarte las boletas
                </span>
            </div>
            {errors.address && 
                <span className={errorStyles}>
                    Este campo es requerido para continuar
                </span>
            }
        </label>

        {/* city - Ciudad */}
        <label className={labelStyles}>
            <span className={spanStyles}>
                Ciudad
            </span>
            <input
                className={inputTextStyles}
                {...register(
                    "city", { required: true, maxLength: 50 }
                )}
                type="text"
                placeholder="Escribe tu ciudad"
            />
            {errors.city && 
                <span className={errorStyles}>
                    Este campo es requerido para continuar
                </span>
            }
        </label>

        {/* municipality - Departamento */}
        <label className={labelStyles}>
            <span className={spanStyles}>
                Departamento
            </span>
            <input
                className={inputTextStyles}
                {...register(
                    "municipality", { required: true, maxLength: 50 }
                )}
                type="text"
                placeholder="Escribe el departamento de donde vienes"
            />
            {errors.municipality && 
                <span className={errorStyles}>
                    Este campo es requerido para continuar
                </span>
            }
        </label>

        {/* day - Qué día asistirás */}
        <div className="block text-lg pb-2">
            <span className="block text-white text-lg">
                Qué día asistirás
            </span>
            <div>
                <input
                    type="radio"
                    className="h-4 w-4 text-[#FF7C9B] focus:ring-[#FF7C9B]"
                    {...register(
                        "day", { required: true }
                    )}
                    value='Martes 1 de Noviembre'
                />
                <label htmlFor="day" className="ml-3 text-base text-white">
                    Martes 1 de Noviembre
                </label>
            </div>
            {/* <div>
                <input
                    type="radio"
                    className="h-4 w-4 text-[#FF7C9B] focus:ring-[#FF7C9B]"
                    {...register(
                        "day", { required: true }
                    )}
                    value='Miércoles 2 de Noviembre'
                />
                <label htmlFor="day" className="ml-3 text-base text-white">
                    Miércoles 2 de Noviembre
                </label>
            </div> */}
            {errors.day && 
                <span className="block pt-2 text-[#e34462] text-lg">
                    Selecciona una opción para continuar
                </span>
            }
        </div>

        <div className="mx-auto py-4 text-center">
            <Button
                btnType="submit"
                text="PAGA TU CENA"
                alt="PAGA TU CENA"
            />
        </div>
    </form>
}

export default StepThree
