import { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";

import Button from '../../../common/button'

import EducacionPoderosa from '../../../resources/Megáfono3.png'

const InputPerson = ({
    register = () => {}, errors = {name: false, email: false},
    number = 1
}) => {
    const spanStyles = "block text-white text-lg"
    const labelStyles = "block pb-2"
    const inputTextStyles = "block w-full text-gray rounded-md pl-2 pr-4 focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
    const errorStyles = "block pt-2 text-[#e34462] text-sm"

    const a_number = number + 1

    return <div className="grid sm:grid-flow-col gap-4 text-black">
            {/* name - Nombre y apellido */}
            <label className={labelStyles}>
                <span className={spanStyles}>
                    Asistente #{a_number}
                </span>
                <input
                    className={inputTextStyles}
                    {...register(
                        `name_${a_number}`, { required: true, maxLength: 50 }
                    )}
                    type="text"
                    placeholder={`Escribe el nombre del asitente #${a_number}`}
                />
                {/* {errors[`name_${a_number}`] && 
                    <span className={errorStyles}>
                        Este campo es requerido para continuar
                    </span>
                } */}
            </label>

            {/* email - Correo electrónico */}
            <label className={labelStyles}>
                <span className={spanStyles}>
                    Correo electrónico #{a_number}
                </span>
                <input
                    className={inputTextStyles}
                    {...register(
                        `email_${a_number}`, { required: true, maxLength: 50 }
                    )}
                    type="email"
                    placeholder={`Escribe el correo electrónico del asitente #${a_number}`}
                />
                {/* {errors[`email_${a_number}`] && 
                    <span className={errorStyles}>
                        Este campo es requerido para continuar
                    </span>
                } */}
            </label>
    </div>
}
const StepTwo = ({
    handleStep = () => {},
    stepTwoData = {}
}) => {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        defaultValues: stepTwoData
    });
    const [inputs, setInputs] = useState([])
    const [quantity, setQuantity] = useState()

    const onSubmit = (data) => {
        handleStep(3, data)
        // console.log(errors)
    }
    
    useEffect(() => {
        // console.log(watch().quantity)
        // console.log(quantity && quantity !== watch().quantity)
        const list = []
        if (quantity !== watch().quantity) {
            for(let i = 0; i < (watch().quantity - 1); i++) {
                // console.log(i)
                list.push(<InputPerson key={i} register={register} errors={errors} number={i + 1} />);
            }
            setInputs(list)
            setQuantity(watch().quantity)
            reset({
                quantity: watch().quantity
            })
        } else {
            // setInputs([])
            // setQuantity(watch().quantity)
        }
    }, [watch().quantity])

    
    // console.log(watch())

    return <div className='w-10/12 sm:w-full mx-auto grid text-center text-white'>
        <img
            className="mx-auto h-[150px] w-auto"
            src={EducacionPoderosa}
            alt="Educacion Poderosa" 
        />
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto py-4 grid text-left w-full"
        >
            <h2 className='text-xl font-bold pb-2'>
                Cena poderosa para:
            </h2>

            {/* quantity - Número de personas */}
            <div className="block text-lg pb-2">
                <span className="block text-white text-lg">
                    Número de asistentes
                </span>
                <div>
                    <input
                        type="radio"
                        className="h-4 w-4 text-[#FF7C9B] focus:ring-[#FF7C9B]"
                        {...register(
                            "quantity", { required: true }
                        )}
                        value='1'
                    />
                    <label htmlFor="quantity" className="ml-3 text-base text-white">
                        1 por $ 650.000 cop
                    </label>
                </div>
                <div>
                    <input
                        type="radio"
                        className="h-4 w-4 text-[#FF7C9B] focus:ring-[#FF7C9B]"
                        {...register(
                            "quantity", { required: true }
                        )}
                        value='2'
                    />
                    <label htmlFor="quantity" className="ml-3 text-base text-white">
                        2 por $ 1’200.000 cop
                    </label>
                </div>
                <div>
                    <input
                        type="radio"
                        className="h-4 w-4 text-[#FF7C9B] focus:ring-[#FF7C9B]"
                        {...register(
                            "quantity", { required: true }
                        )}
                        value='4'
                    />
                    <label htmlFor="quantity" className="ml-3 text-base text-white">
                        4 por  $ 2’200.000 cop 
                    </label>
                </div>
                {errors.quantity && 
                    <span className="block pt-2 text-[#e34462] text-lg">
                        Selecciona una opción para continuar
                    </span>
                }
            </div>

            {inputs}

            <p className='text-white text-lg font-[350] mb-4'>
                Asistirás a una experiencia gastronómica sin igual y conocerás a algunxs de nuestrxs poderosxs.
            </p>
            <div className="mx-auto py-4">
                <Button
                    btnType="submit"
                    text="CONTINUAR"
                    alt="Continuar"
                />
            </div>
        </form>
    </div>
}

export default StepTwo