import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"

import { useSaveDonationFunction } from '../../hooks/useSaveDonationFunction'
import { useUpdateDonationWithTransaction } from '../../hooks/useUpdateDonationWithTransaction'

import HeaderLogo from '../../common/header'
import Footer from '../../common/footer'
import Loading from '../../common/loading'
import Modal from '../../common/modal'

import Steps from './steps'
import BackButton from './backButton'
import StepOne from './stepOne'
import StepTwo from './stepTwo'
import StepThree from './stepThree'

import './index.css'

const DinnerPage = () => {
    let navigate = useNavigate();

    const [step, setStep] = useState(1)
    const [stepOneData, setStepOneData] = useState(null)
    const [stepTwoData, setStepTwoData] = useState(null)
    const [stepThreeData, setStepThreeData] = useState(null)

    const [open, setOpen] = useState(false)
    const [state, setState] = useState('fail')
    const [message, setMessage] = useState()

    useEffect(() => {
        const quantity = stepTwoData && stepTwoData.quantity
        console.log({
            ...stepOneData,
            attendes: stepTwoData,
            ...stepThreeData,
            quantity
        })
        if (step === 4) {
            async function Pay() {
                return await useSaveDonationFunction({
                    ...stepOneData,
                    attendes: stepTwoData,
                    ...stepThreeData,
                    quantity
                })
            }
            Pay().then(async res => {
                // console.log('res', res)
                await handleDonation(res)
            }).catch(err => {
                // console.log('err', err.message)
                setStep(3)
                setOpen(true)
                setState('fail')
                setMessage(err.message)
            })
        }
    }, [step])

    const handleNavigation = () => {
        if (step === 4) return
        if (step > 1) {
            setStep(step - 1)
        } else {
            setStepOneData(null)
            navigate('/');
        }
    }

    const handleStep = (step, data) => {
        // console.log(step, data)
        if (step === 2) setStepOneData(data)
        if (step === 3) setStepTwoData(data)
        if (step === 4) setStepThreeData(data)
        setStep(step)
    }

    const handleDonation = async (wompi_payload) => {
        setStep(3)
        const checkout = new window.WidgetCheckout(wompi_payload)
        return await checkout.open(async result => {
            // console.log('Result', result)
            var transaction = result.transaction
            // console.log('Transaction ID: ', transaction.id)
            // console.log('Transaction object: ', transaction)

            setStepOneData(null)
            setStepTwoData(null)
            setStepThreeData(null)
            setStep(1)
            // eslint-disable-next-line react-hooks/rules-of-hooks  
            await useUpdateDonationWithTransaction(transaction)
            
            if (transaction.status === "APPROVED") {
                setState('ok')
                setMessage('Gracias, pronto te llegara un correo con mas informacion')
                setOpen(true)
                return {
                    status: true,
                    message: 'Donación almacenada',
                    transaction
                }
            } else {
                setState('fail')
                setMessage(transaction.statusMessage)
                setOpen(true)
                return {
                    status: false,
                    message: 'Donación fallida',
                    transaction
                }
            }
        })
    }

    return <div className='dinner-page grid grid-flow-row'>
        <HeaderLogo />
        <Steps step={step} />
        <div className={step === 1 ? "grid w-11/12 mx-auto" : "grid w-10/12 sm:w-2/4 mx-auto"}>
            <BackButton action={handleNavigation} />
            {
                ((step === 2) || (step === 3 )) && <>
                    <h1 className="text-[#FF7C9B] text-center font-bold text-2xl py-4">
                        ¡Tu aporte es muy poderoso!
                    </h1>
                    <p className="text-white text-center text-xl">
                    El valor de una Educación Poderosa es realmente incalculable.
                    </p>
                </>
            }
            {
                step === 4 && <>
                    <h1 className="text-[#FF7C9B] text-center font-bold text-2xl py-4">
                        ¡Tu aporte es muy poderoso!
                    </h1>
                    <p className="text-white text-center text-xl">
                        Estamos recibiendo tu aporte.
                    </p>
                </>
            }
        </div>
        
        {/* STEPS */}
        <div className='mx-auto pt-2 pb-5'>
            {step === 1 && <StepOne handleStep={handleStep} stepOneData={stepOneData} />}
            {step === 2 && <StepTwo handleStep={handleStep} stepTwoData={stepTwoData} />}
            {step === 3 && <StepThree handleStep={handleStep} stepThreeData={stepThreeData} />}
            {step === 4 && <div className='grid justify-center py-32'><Loading/></div>}
        </div>
        
        <Modal open={open} state={state} message={message} close={() => {setOpen(false);setState(null);}}/>
        <Footer />
    </div>
}

export default DinnerPage